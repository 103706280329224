/*--------------------------------------------------------------
# Theming - Variables specific to the theme, using colors 
# defined in the main Variables file as well as here
--------------------------------------------------------------*/
:root {
	/* Theme Colors Go Here */
	--theme-black: #151412;
    --yellow: #ffbe39;
    --orange: #f98816;
	--dark-orange: #85372a;
	--white: #FFF;

	/* Custom Fonts */
	--font-plain: sans-serif;
	--font-special: AndroclesOpti, serif;

	/* Defaults */
	--background-color-dark: var(--black);
	--background-color-medium: var(--grey);
	--background-color-light: var(--white);
	--border: 1px solid var(--black);
	--border-radius: 10px;
	--border-radius-small: 5px;
	--border-radius-tiny: 3px;
	--border: 1px solid var(--black);
	--border-heavy: 4px solid var(--black);
	--border-alt: 1px solid var(--grey);
	--border-heavy-alt: 4px solid var(--grey);
	--border-light: 1px solid var(--light-grey);
	--border-dashed: 2px dashed var(--black);
	--border-dashed-alt: 2px dashed var(--white);
	--border-dashed-light: 2px dashed var(--light-grey);
	--box-shadow-style: 0px 1px 2px;
	--box-shadow-color: rgba(0, 0, 0, 0.25);
	--box-shadow-style-alt: 0px 2px 4px;
	--box-shadow-color-alt: rgba(0, 0, 0, 0.25);
	--caption-color: var(--black);
	--caption-text-color: var(--white);
	--selection-color: var(--black);
	--selection-text-color: var(--white);
	--text-color-dark: var(--black);
	--text-color-medium: var(--grey);
	--text-color-light: var(--white);
	--text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

	/* Links */
	--link-color: var(--dark-orange);
	--link-color-hover: var(--orange);

    /* Button Colors */
	--button-color: var(--dark-orange);
	--button-text-color: var(--white);
	--button-color-alt: var(--orange);
	--button-text-color-alt: var(--theme-black);
	--button-color-disabled: var(--grey);
	--button-text-color-disabled: var(--black);
	--search-submit-color: var(--dark-orange);
	--search-submit-text-color: var(--white);

    /* Button Hovers */
	--button-border-color-hover: var(--orange);
	--button-color-hover: var(--orange);
	--button-text-color-hover: var(--theme-black);
	--button-color-alt-hover: var(--dark-orange);
	--button-text-color-alt-hover: var(--white);
	--search-submit-color-hover: var(--orange);
	--search-submit-text-color-hover: var(--theme-black);

	/* Menu Colors */
	--nav-item-color: var(--white);
	--nav-item-color-hover: var(--grey);
	--menu-toggle-color: var(--orange);
	--menu-toggle-text-color: var(--black);
	--off-canvas-color: var(--theme-black);
	--off-canvas-border: 5px solid var(--orange);
	--off-canvas-nav-item-color: var(--white);
	--off-canvas-nav-item-color-hover: var(--orange);
	--off-canvas-sub-menu-button-color: var(--dark-orange);
	--off-canvas-sub-menu-button-text-color: var(--white);
	--sub-menu-color: var(--grey);
	--sub-menu-text-color: var(--black);
	--sub-menu-item-color-hover: var(--black);
	--sub-menu-item-text-color-hover: var(--white);

	/* Header Colors */
	--site-header-background-color: var(--black);
	--site-header-text-color: var(--white);

	/* Footer Colors */
	--site-footer-background-color: var(--black);
	--site-footer-text-color: var(--white);
	--copyright-color: var(--black);
	--copyright-text-color: var(--white);

	/* Cookie Bar */
	--cookie-bar-color: var(--theme-black);
	--cookie-bar-text-color: var(--white);

	/* Page Header */
	--page-header-color: var(--theme-black);
	--page-header-text-color: var(--white);

	/* Slides */
	--slide-background-color: var(--white);
	--slide-text-color: var(--black);
	--slider-navigation-button-color: var(--black);
	--slider-navigation-button-color-current: var(--grey);
	--slider-navigation-button-color-hover: var(--grey);
	--slider-scrollbar-track-color: var(--grey);
	--slider-scrollbar-thumb-color: var(--black);

	/* Post Card Block & Cards */
	--post-card-block-background-color: var(--black);
	--post-card-background-color: var(--white);
	--post-card-background-color-sticky: var(--grey);
	--post-card-border-color-sticky: var(--white);
	--post-title-color: var(--black);
	--post-title-color-hover: var(--orange);

	/* Pagination */
	--pagination-color: var(--dark-orange);
	--pagination-color-hover: var(--white);
	--pagination-text-color: var(--white);
	--pagination-text-color-hover: var(--theme-black);

	/* Affiliate Notice */
	--affiliate-notice-color: var(--black);
	--affiliate-notice-text-color: var(--white);
	--affiliate-notice-link-color: var(--white);
	--affiliate-notice-link-color-hover: var(--white);

	/* Post Navigation */
	--post-navigation-color: var(--theme-black);
	--post-navigation-text-color: var(--white);
	--post-navigation-color-hover: var(--dark-orange);
	--post-navigation-text-color-hover: var(--white);

	/* Breadcrumbs */
	--breadcrumb-color: var(--white);
	--breadcrumb-color-hover: var(--white);

	/* Newsletter Block */
	--newsletter-background: transparent;
	--newsletter-text-color: var(--white);

	/* Progress Bar */
	--progress-bar-gradient-start: var(--dark-orange);
	--progress-bar-gradient-end: var(--orange);
	--progress-bar-color: linear-gradient(90deg, var(--progress-bar-gradient-start) 0%, var(--progress-bar-gradient-end) 100%);
}