.hub-widget {
    background: var(--theme-black);
    border-radius: var(--border-radius);
    color: var(--white);
    padding: 20px;
    padding: 2rem;
    text-align: center;

    .widget-title {
        border-bottom: none;
        padding-bottom: 0;
    }

    .hub-image {
        margin: 0 auto 20px;
        margin: 0 auto 2rem;
    }

    .button {
        margin-bottom: 10px;
        margin-bottom: 1rem;
        width: 100%;
    }
}