.wp-block-wyvern-plugin-newsletter {
    .wrapper {
        .newsletter-prompt {
            font-size: 32px;
            font-size: 3.2rem;
            text-transform: uppercase;
        }

        .button {
            background: var(--black);

            &:hover,
            &:focus,
            &:active {
                background: var(--dark-orange);
            }
        }
    }
}