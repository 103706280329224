.bbpress {
    #bbpress-forums {
        li {
            &.bbp-topic-title {
                float: none;
                width: 100%;
                
                @media (--menu-button-query) {
                    float: left;
                    width: 50%;
                }
                
                @media (--content-query) {
                    width: 55%;
                }
            }

            &.bbp-topic-freshness {
                float: none;
                text-align: left;
                width: 100%;
                
                @media (--menu-button-query) {
                    float: left;
                    width: 50%;
                }
                
                @media (--content-query) {
                    width: 22%;
                }
            }
        }

        .bbp-forum-info {
            .bbp-forum-content {
                font-size: 16px;
                font-size: 1.6rem;
            }
        }
    }

    .forum {
        max-width: 100vw;
    }

    .bbp-topic-voice-count,
    .bbp-topic-reply-count {
        display: none;

        @media (--content-query) {
            display: block;
        }
    }

    .bbp-forums-list {
        display: none;
    }

    .registration-prompt {
        .button-container {
            text-align: center;
        }
    }
}