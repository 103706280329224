.entry-content {
    padding: 20px;
    padding: 2rem;

    .home & {
        background: transparent;
    }

    .page:not(.home) &,
    .bbpress & {
        margin: 20px auto;
        margin: 2rem auto;
        max-width: 96%;
        width: 96%;

        @media (--alignfull-query) {
            margin: 20px auto;
            margin: 2rem auto;
            max-width: 1440px;
        }
    }

    .page:not(.home) & {
        padding: 20px;
        padding: 2rem;
    }
}