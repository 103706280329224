.header-navigation {
    .main-menu-container {
        .menu {
            .menu-item {
                line-height: 1;
                margin: 0 10px;
                margin: 0 1rem;

                a {
                    font-size: 20px;
                    font-size: 2rem;
                }
            }
        }
    }
}