.breadcrumbs {
    .breadcrumb {
        a {
            background: var(--dark-orange);
            border-radius: var(--border-radius);
            color: var(--breadcrumb-color);
            display: inline-block;
            font-weight: 700;
            line-height: 1;
            padding: 5px 10px;
            padding: 0.5rem 1rem;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            transition: background-color var(--linear);

            &:hover,
            &:focus,
            &:active {
                background: var(--theme-black);
                color: var(--breadcrumb-color-hover);
            }
        }
    }
}