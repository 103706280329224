.post-title {
    a {
        color: var(--post-title-color);

        &:hover,
        &:focus,
        &:active {
            color: var(--post-title-color-hover);
        }
    }
}

.after-header {
    width: 100%;
}