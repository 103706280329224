.wp-block-wyvern-plugin-character-of-the-week {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 630px;
    overflow: hidden;
    position: relative;

    @media (--menu-query) {
        min-height: 850px;
    }

    .top {
        background-color: var(--orange);
        margin-left: -5%;
        padding: 15px 10px 10px 7%;
        padding: 1.5rem 1rem 1rem 7%;
        position: absolute;
        text-align: left;
        top: 20px;
        transform: rotate(-5deg);
        width: 110%;

        @media (--menu-query) {
            padding-top: 20px;
            padding-top: 2rem;
            top: 0px;
        }

        @media (--alignfull-query) {
            padding-left: 20px;
            padding-left: 2rem;
        }

        .top-heading {
            color: var(--white);
            font-size: 24px;
            font-size: 2.4rem;
            font-weight: 400;
            line-height: 1;
            margin: 0;
            text-transform: uppercase;

            @media (--menu-query) {
                font-size: 60px;
                font-size: 6rem;
            }
        }
    }

    .bottom {
        background-color: var(--theme-black);
        bottom: 70px;
        margin-left: -5%;
        padding: 10px 20px 20px;
        padding: 1rem 2rem 2rem;
        padding-right: 7%;
        position: absolute;
        text-align: right;
        transform: rotate(-5deg);
        width: 110%;

        @media (--menu-query) {
            bottom: 35px;
        }

        @media (--alignfull-query) {
            padding-right: 20px;
            padding-right: 2rem;
        }

        .character-name,
        .character-media {
            font-family: var(--font-special);
            margin: 0;
            text-transform: uppercase;
        }

        .character-name {
            color: var(--orange);
            font-size: 24px;
            font-size: 2.4rem;

            @media (--menu-query) {
                font-size: 36px;
                font-size: 3.6rem;
            }
        }

        .character-media {
            color: var(--white);
            font-size: 18px;
            font-size: 1.8rem;
            line-height: 1;
        }
    }

    .buttons {
        bottom: 0;
        padding: 10px;
        padding: 1rem;
        position: absolute;
        text-align: right;
        width: 100%;
        z-index: 2;
    }
}