.wp-block-wyvern-plugin-review {
    background: var(--theme-black);
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: row wrap;
    padding: 0 0 20px;
    padding: 0 0 2rem;

    @media (--menu-query) {
        padding: 0 0 10px;
        padding: 0 0 1rem;
    }

    .block-heading {
        color: var(--orange);
        flex: 0 1 100%;
        line-height: 1;
        margin: 0 0 10px;
        margin: 0 0 1rem;
        padding: 20px 20px 0;
        padding: 2rem 2rem 0;
    }

    .score-container {
        flex: 0 0 20%;
        margin: 0 auto;
        padding: 30px;
        padding: 3rem;
        position: relative;
    }

    .score {
        align-items: center;
        background: var(--white);
        border-radius: 100%;
        color: var(--theme-black);
        display: flex;
        flex-flow: row wrap;
        font-family: var(--font-special);
        font-size: 80px;
        font-size: 8rem;
        height: 100px;
        justify-content: center;
        letter-spacing: -15px;
        line-height: 80px;
        margin: 0 auto;
        padding-right: 18px;
        padding-right: 1.8rem;
        padding-top: 10px;
        padding-top: 1rem;
        width: 100px;
    }

    .planet-ring {
        background-image: url('../../img/planet-ring.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 130px;
        left: 18%;
        position: absolute;
        top: 20px;
        width: 125px;
        z-index: 2;
    }

    .score-text {
        display: block;
        margin-top: 40px;
        margin-top: 4rem;
        text-align: center;
    }

    .review-text {
        flex: 0 1 calc(100% - 40px);
        padding: 20px 20px 0;
        padding: 2rem 2rem 0;

        @media (--menu-query) {
            flex-basis: calc(80% - 40px);
            padding: 10px 10px 0;
            padding: 1rem 1rem 0;
        }
    }

    .review-recap {
        color: var(--white);
        margin: 0;
    }
}